import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserManagementService } from './user-management/user-management.service';
import { UserRolesService } from './user-roles.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(private userService: UserManagementService, private router: Router, private userRole: UserRolesService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userDetail = this.userService.getUserDetail();
    if (userDetail && userDetail.id) {
      if (state.url === '/login') {
        this.router.navigate(['/']);
        return false;
      } else {
        const isAuthenticate = this.userRole.isAuthenticate(state.url, userDetail.role);
        if (!isAuthenticate) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }
    } else {
      if (state.url === '/login') {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }

}
