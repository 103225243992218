<app-loader [loading]="loading"></app-loader>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="wrapper fadeInleft bg-login-image">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-6 p-0 text-center">
                        <img src="./assets/images/logo-wwtp.png" id="icon" alt="logo">        
                        <img src="./assets/images/login-logo1.png" id="icon" alt="logo">          
                    </div>
                    <div class="col-lg-4 col-md-6 p-0">
                        <div id="formContent">
                            <div class="login-form">
                                <div class="login-tabar">
                                    <button class="btn login-tabs" [ngClass]="{'login-active' : isLogin == true}" (click)="onClickLogin('login')">Login</button>
                                    <button class="btn login-tabs" [ngClass]="{'login-active' : isLogin == false}" (click)="onClickLogin('register')">Register</button>
                                </div>
                                <div *ngIf="isLogin == true">
                                    <form [formGroup]="loginForm" (ngSubmit)="onLogin($event)" id="loginForm" >
                                        <div class="login-formGroup">
                                            <label class="login-label">Username</label>
                                            <input type="text" id="login" class="login-formcontrol"  formControlName="email" name="username" placeholder="Username" autocomplete="off">
                                        </div>
                                        <div class="login-formGroup">
                                            <label class="login-label">Password</label>
                                            <input type="text" id="password" class="login-formcontrol pastype" formControlName="password" name="login" placeholder="**********" [readonly]="btnDisabled == 1 ? true : false"  autocomplete="off">
                                        </div>
                                        <button type="submit" class="btn login-btn" [disabled]="loginForm.invalid || btnDisabled == 1">Log In</button>
                                        <p *ngIf="btnDisabled == 1" class="login-fail-class">Login button has been disabled due to too many failed attempts. Please try again in  <b>{{timeLeft}}</b></p>
                                    
                                    </form>
                                    <div class="seperator-text">Or</div>
                                    <button class="btn btn-social-icon btn-google" (click)="signInWithGoogle()">
                                        <span><img src="./assets/images/google-vector.png"class="gogleicon"/></span>
                                        <span>Login With Google</span>
                                    </button>
                                </div>
                                <div *ngIf="isLogin == false">
                                    <button class="btn btn-social-icon btn-google" (click)="signUpWithGoogle()">
                                        <span><img src="./assets/images/google-vector.png"class="gogleicon"/></span>
                                        <span>Register With Google</span>
                                    </button>
                                </div>
                            </div>
                         </div>
                           
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


