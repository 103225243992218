import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserManagementService } from '../user-management/user-management.service';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  private innerMessage = new Subject<any>();
  private siteURL =  `${environment.baseUri}category/`;
  private baseURL =  `${environment.baseUri}site/`;
  private agentURL =  `${environment.baseUri}agent/`;
  private opexURL =  `${environment.baseUri}opex/`;
  private baseuri = `${environment.baseUri}/`;
  private reportURL =  `${environment.baseUri}reports/`;
  private cronURL =   `${environment.baseUri}cron/`;
  private crondailycntURL =   `${environment.baseUri}Crondailycnt/`;
  private mixedURL = 'https://njserv.pure.bi/api/site/';
  private base = `${environment.baseUri}`;
  //private mixedURL = 'http://localhost:4000/api/site/';
  private siteId;
  public isParameterEnable = false;
  public isScoreEnable = false;
  private basenodeURL = `${environment.nodeUrl1}`;
  private nodeURL = `${environment.nodeUrl1}allModuleReports`;
  private nodeURL1 = `${environment.nodeUrl1}addReports`;
  private baseURL1 = `${environment.baseUri}site`;
  private nodeURL2 = `${environment.nodeUrl1}allDashboard`;
  private nodeURL3 = `${environment.nodeUrl1}singleReport`;
  private nodeURL4 = `${environment.nodeUrl1}editReports`;

  constructor(
    private http: HttpClient, private userService: UserManagementService
  ) { }

  get site_id() {
    return this.siteId;
  }
  sendMessage(message: any) {
    this.innerMessage.next(message);
  }

  clearMessage() {
    this.innerMessage.next();
  }

  getMessage(): Observable<any> {
    return this.innerMessage.asObservable();
  }

  public setSiteId(id) {
    this.siteId = id;
  }

  addsite(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.baseURL}create`, obj).subscribe(response => {
        resolve(response);
      }, error => reject(error));
    });
  }

  sitemoduleparameter() {
    const request = {'purpose': 'sitemoduleparameter', 'site_id': this.siteId};
    return this.http.post(`${this.siteURL}sitemoduleparameter`, request).toPromise();
  }

  addsiteparameter(obj) {
    return this.http.post(`${this.siteURL}addsiteparameter`, obj).toPromise();
  }

  sitemodulescore() {
    const request = {'purpose': 'sitemodulescore', 'site_id': this.siteId};
    return this.http.post(`${this.siteURL}sitemodulescore`, request).toPromise();
  }
  getadmindashboard(request: any){
    return this.http.post(`${this.base}home`, request).toPromise();
  }

  addsitemodulescore(obj) {
    return this.http.post(`${this.siteURL}addsitemodulescore`, obj).toPromise();
  }

  allsite() {
    const request = {'purpose': 'allsite'};
    return this.http.post(`${this.siteURL}allsite`, request).toPromise();
  }
  getPreview(request, url){
    return this.http.post(`${this.mixedURL}`+url, request).toPromise();
  }

  saveDashboard(request){
    return this.http.post(`${this.mixedURL}saveCorporateReport`, request).toPromise();
  }
  getDashboardList(request){
    return this.http.post(`${this.mixedURL}getCorporateReportList`, request).toPromise();
  }
  getDashboardReportDetails (request) : Observable<any>{
    return this.http.post(`${this.mixedURL}getCorporateReportDetails`, request);
  }
  getSiteList(request){
    return this.http.post(`${this.baseuri}corporation/corporate/corpsites`, request).toPromise();
  }
  corporate(request) {
    return this.http.post(`${this.baseURL}corporate`, request).toPromise();
  }

  excelexport(siteId) {
    const request = {'purpose': 'excelexport', 'site_id': siteId};
    return this.http.post(`${this.siteURL}excelexport`, request).toPromise();
  }
  uploadData(obj) {
    return this.http.post(`${this.siteURL}upload_data`, obj).toPromise();
  }
  uploadPrice(obj) {
    return this.http.post(`${this.siteURL}upload_price`, obj).toPromise();
  }
  
  deleteSite(obj) {
   return this.http.post(`${this.baseURL}delete`, obj).toPromise();
  }
  getSiteInfoDashboard(obj) {
    return this.http.post(this.baseURL, obj).toPromise();
  }
  getSiteInfo(obj) {
     return this.http.post(this.baseURL, obj).toPromise();
    //return this.http.post(this.nodeURL, obj).toPromise();
  }
  getSiteInfo1(obj) {
    // return this.http.post(this.baseURL, obj).toPromise();
    return this.http.post(this.nodeURL, obj).toPromise().catch(_ => this.getSiteInfo1(obj));
    return this.http.post(this.nodeURL, obj).toPromise().catch(err => {
      console.error(err);
      return this.getSiteInfo1(obj);
    });
  }
  getSiteInfo2(obj) {
    // return this.http.post(this.baseURL, obj).toPromise();
    return this.http.post(this.nodeURL3, obj).toPromise();
  }
  addSiteInfo(obj) {
    return this.http.post(this.nodeURL1, obj).toPromise();
    //return this.http.post(this.baseURL, obj).toPromise();
  }
  editSiteInfo(obj) {
    return this.http.post(this.nodeURL4, obj).toPromise();
    //return this.http.post(this.baseURL, obj).toPromise();
  }
  getReportInfo(obj) {
    //return this.http.post(this.baseURL, obj).toPromise();
   return this.http.post(this.nodeURL, obj).toPromise();
 }
  getModuleList(obj) {
    return this.http.post(`${this.basenodeURL}getModulelist`, obj).toPromise();
  }
  getMixModuleList(obj) {
    return this.http.post(`${this.basenodeURL}getMixModulelist`, obj).toPromise();
  }

  getParameterList(obj) {
    return this.http.post(`${this.basenodeURL}getParameterlist`, obj).toPromise();
  }
  getMixParameterlist(obj) {
    return this.http.post(`${this.basenodeURL}getMixParameterlist`, obj).toPromise();
  }
  addMixedDashboard(obj) {
    return this.http.post(`${this.basenodeURL}addCorporateMixReport`, obj).toPromise();
  }
 
  updateMixedDashboard(obj) {
    return this.http.post(`${this.basenodeURL}editCorporateMixReport`, obj).toPromise();
  } 
  saveMixedDashboard(obj) {
    return this.http.post(`${this.basenodeURL}saveCorporateReport`, obj).toPromise();
  }

  getMixedDashboardDetail(obj,url) {
    return this.http.post(`${this.basenodeURL}`+url, obj).toPromise();
  }
 
  getNewSiteInfo(obj) {
    return this.http.post(this.baseURL, obj).toPromise();
  }
  
  getOpexInfo(obj) {
    return this.http.post(this.baseURL, obj).toPromise();
  }
  uploadOpexInfo(obj) {
    return this.http.post(`${this.opexURL}/upload_opexdata`, obj).toPromise();
  }
  addSiteUser(obj) {
    return this.http.post(`${environment.baseUri}auth`, obj).toPromise();
  }
  allReportSite() {
    const user = this.userService.getUserDetail();
    const request = {'purpose': 'addreport', 'role': user.role, 'user_id': user.id};
    return this.http.post(`${this.reportURL}`, request).toPromise();
  }

  allReport(request) {
    return this.http.post(`${this.reportURL}`, request).toPromise();
  }


  getOpexModule(){
    const request = {'purpose': 'allmodules'};
    return this.http.post(`${this.siteURL}opexURL`, request).toPromise();

  }

  updateSiteDiagram(obj){

    return this.http.post(`${this.siteURL}updatesitemodule`, obj).toPromise();

  }

  updateSiteDetails(request){
    return this.http.post(`${this.baseURL}`, request).toPromise();
  }

  uploadPdfData(obj) {
    return this.http.post(`${this.siteURL}upload_pdf`, obj).toPromise();
  }

  getPDFUrl(filename) {
    return  `${environment.baseUri}uploads/${filename}`;
  }

  getUploadAlert(site_id){
    const request = {'purpose': 'save_alert', 'site_id':site_id};
  
    return this.http.post(`${this.cronURL}`,request).toPromise();
  }

  getRangeModuleReport(request){
   
    //return this.http.post(`${this.baseURL}`,request).toPromise();
    return this.http.post(`${this.basenodeURL}getReportDetailsByRange`,request).toPromise();
  }

  getOpexRangeModuleReport(request){

    return this.http.post(`${this.opexURL}`,request).toPromise();
  }

  getRangeModuleReportByDateTime(request){
   
    return this.http.post(`${this.basenodeURL}getDetailsByReportId`,request).toPromise();
  }

  getOpexRangeModuleReportByDateTime(request){
    
    return this.http.post(`${this.opexURL}`,request).toPromise();
  }
  deleteModule(request){
    return this.http.post(`${this.baseURL}`,request).toPromise();
  }
  getUploadDailyCntr(site_id){
    const request = {'purpose': 'save_daily_counter', 'site_id':site_id};
  
    return this.http.post(`${this.crondailycntURL}`,request).toPromise();
  }
  getReportBydate (request,url){
    return this.http.post(`${this.mixedURL}`+url, request).toPromise();
  }
  getCorpReportBydate (request,url){
    return this.http.post(`${this.basenodeURL}`+url, request).toPromise();
  }
  getMixReportDetailsByDate (request,url){
    return this.http.post(`${this.basenodeURL}`+url, request).toPromise();
  }
  emailsendUser(obj) {
    return this.http.post(`${environment.baseUri}alerts`, obj).toPromise();
  }
  siteuers(obj) {
    return this.http.post(`${environment.baseUri}auth`, obj).toPromise();
  }
  deleteGroupModule(request){
    return this.http.post(`${this.baseURL}`,request).toPromise();
  }

  deleteSubGroupModule(request){
    return this.http.post(`${this.baseURL}`,request).toPromise();
  } 
  deleteAlerts(request: any){
    return this.http.post(`${this.base}alerts`, request).toPromise();
  }
   
  getAgentData(request){
    
    return this.http.post(`${this.agentURL}`,request).toPromise();
  }
  getAgentInfo(request){
    return this.http.post(`${this.agentURL}`,request).toPromise();
  }
  addModuleSiteInfo(obj) {
    //return this.http.post(this.nodeURL1, obj).toPromise();
    return this.http.post(this.agentURL, obj).toPromise();
  }
  getAllAgentList(obj) {
    return this.http.post(this.agentURL, obj).toPromise();
  }
  getAllAgentUserList(obj) {
    return this.http.post(this.agentURL, obj).toPromise();
  }
  deleteAgentUserbyId(obj) {
    return this.http.post(`${environment.baseUri}auth`, obj).toPromise();
  }
  deleteAgentbyId(obj) {
    return this.http.post(this.agentURL, obj).toPromise();
  }
  chpAgentUserbyId(obj){
    return this.http.post(this.agentURL, obj).toPromise();
  }
  uploadDatabyAgent(obj) {
    return this.http.post(this.agentURL, obj).toPromise();
  }
  changeLabUserPassword(obj){
    return this.http.post(this.agentURL, obj).toPromise();
  }
  getGoogleInfo(obj){
    return this.http.post(`${environment.baseUri}auth`, obj).toPromise();
  }
  updateGoogleUserInfo(obj){
    return this.http.post(`${environment.baseUri}auth`, obj).toPromise();
  }
  getPeriodicDetails(obj) {
    return this.http.post(`${this.basenodeURL}getPeriodicReportDetails`, obj).toPromise();
  }
  getPeriodicReportList(obj) {
    return this.http.post(`${this.basenodeURL}getPeriodicReportList`, obj).toPromise();
  }
  getProjectList(obj) {
    return this.http.post(`${this.basenodeURL}getProjectList`, obj).toPromise().catch(err => {
      console.error(err);
      return this.getProjectList(obj);
    });
  }
  addProject(obj) {
    return this.http.post(`${this.basenodeURL}addProject`, obj).toPromise();
  }
  deleteProject(obj) {
    return this.http.post(`${this.basenodeURL}deleteProcessReportSite`, obj).toPromise();
  }
  getreportById(obj) {
    return this.http.post(this.baseURL, obj).toPromise();
  }
  getChatInfo(obj){
    return this.http.post(`${this.basenodeURL}chatgpt`,obj).toPromise();
  }
}
