import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/compiler';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "ag-grid-enterprise";
import * as Highcharts from 'highcharts';

// LicenseManager.setLicenseKey("Evaluation_License_Valid_Until__27_October_2018__MTU0MDU5NDgwMDAwMA==d47615e03f843a16f65fc1bbf9c7cb53");
LicenseManager.setLicenseKey(
  'Arkin_HIS_3Devs28_March_2020__MTU4NTM1MzYwMDAwMA==ce1eed0a6018afcf2a06b081b503f06a'
);

Highcharts.setOptions({
  title: {
    style: {
      color: 'white'
    }
  },
  colors:[
    '#fffdcf',
    '#ff7070',
    '#d4fc88',
    '#ff63fe',
    '#ffb026',
    '#ffc5ab',
    '#c2da46',
    '#ff825b',
    '#ff6188',
    '#cfa07c',
    '#a3d67e',
    '#ffe10f',
    '#d9b6cd',
    '#ffe278',
    '#80ffc6',
  ],
  xAxis: {
    labels: {
        style: {
            color: 'white'
        }
    }
}
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
